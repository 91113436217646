import React from 'react'
import { graphql } from 'gatsby'
import { Link as RouterLink } from 'gatsby'

import RichTextRenderer from 'components/RichTextRenderer'
import PlainTextRenderer from 'components/PlainTextRenderer'

import {
  Section,
  Container,
  Row,
  Column,
  Box,
  Heading,
  Text,
  Link,
} from 'components'
import Layout from 'components/Layout'

import Navigation from './components/Navigation'

const Term = ({ data }) => {
  const {
    glossary: {
      term,
      description: { json: description },
      seoDescription: {seoDescription },
    },
    nextGlossary,
    previousGlossary,
  } = data

  return (
    <Layout
      seo={{
        title: term,
        description: seoDescription,
      }}
    >
      <Section spacing="small">
        <Container>
          <Box sx={{ display: 'flex' }}>
            <Text sx={{ fontSize: 0, ml: 1 }}>Glossar</Text>
            <Text variant="muted" sx={{ ml: 1 }}>
              / {term}
            </Text>
          </Box>

          <Box mt={3} />

          <Navigation />

          <Heading.H2 as="h2" sx={{ pt: 5 }}>
            Glossar
          </Heading.H2>

          <Box
            sx={{
              pt: [3, 6],
              pb: [6, 10],
              borderBottom: '1px solid',
              borderColor: 'colorBlue10',
            }}
          >
            <Row>
              <Column size={[1, 1, 4 / 12, null]}>
                <Heading.H4 as="h1">{term}</Heading.H4>
              </Column>
              <Column size={[1, 1, 8 / 12, null]}>
                <RichTextRenderer>{description}</RichTextRenderer>

                <Link
                  as={RouterLink}
                  to="/glossar"
                  sx={{
                    display: 'block',
                    fontSize: 0,
                    color: 'darkNavy40',
                    textDecoration: 'none',
                  }}
                >
                  <Box
                    as="svg"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    sx={{
                      width: 12,
                      height: 9,
                      mr: 2,
                    }}
                  >
                    <path
                      d="M11.25 4.49984C11.25 4.84984 11.0167 5.08317 10.6667 5.08317H2.73334L5.24167 7.59151C5.475 7.82484 5.475 8.17484 5.24167 8.40817C5.125 8.52484 5.00834 8.58317 4.83334 8.58317C4.65834 8.58317 4.54167 8.52484 4.425 8.40817L0.925004 4.90817C0.866671 4.84984 0.808337 4.7915 0.808337 4.73317C0.750004 4.6165 0.750004 4.4415 0.808337 4.2665C0.866671 4.20817 0.866671 4.14984 0.925004 4.0915L4.425 0.591504C4.65834 0.358171 5.00834 0.358171 5.24167 0.591504C5.475 0.824837 5.475 1.17484 5.24167 1.40817L2.73334 3.9165H10.6667C11.0167 3.9165 11.25 4.14984 11.25 4.49984Z"
                      fill="#8093AB"
                    />
                  </Box>
                  zurück zum Glossar
                </Link>
              </Column>
            </Row>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              justifyContent:
                (previousGlossary &&
                  nextGlossary && ['center', 'space-between']) ||
                (!previousGlossary && nextGlossary && ['center', 'flex-end']),
              py: 3,
            }}
          >
            {previousGlossary && (
              <Link
                as={RouterLink}
                to={`/glossar/${previousGlossary?.slug}`}
                sx={{
                  display: 'block',
                  fontSize: 3,
                  fontWeight: 'bold',
                  color: 'darkNavy40',
                  textDecoration: 'none',
                  textAlign: 'center',
                  py: 3,
                }}
              >
                <Box
                  as="svg"
                  viewBox="0 0 14 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  sx={{
                    width: 14,
                    height: 11,
                    mr: 4,
                  }}
                >
                  <path
                    d="M13.75 5.5C13.75 5.95 13.45 6.25 13 6.25H2.80005L6.02505 9.475C6.32505 9.775 6.32505 10.225 6.02505 10.525C5.87505 10.675 5.72505 10.75 5.50005 10.75C5.27505 10.75 5.12505 10.675 4.97505 10.525L0.475049 6.025C0.400049 5.95 0.325049 5.875 0.325049 5.8C0.250049 5.65 0.250049 5.425 0.325049 5.2C0.400049 5.125 0.400049 5.05 0.475049 4.975L4.97505 0.475C5.27505 0.175 5.72505 0.175 6.02505 0.475C6.32505 0.775 6.32505 1.225 6.02505 1.525L2.80005 4.75H13C13.45 4.75 13.75 5.05 13.75 5.5Z"
                    fill="currentColor"
                  />
                </Box>

                {previousGlossary?.term}
              </Link>
            )}
            {nextGlossary && (
              <Link
                as={RouterLink}
                to={`/glossar/${nextGlossary?.slug}`}
                sx={{
                  display: 'block',
                  fontSize: 3,
                  fontWeight: 'bold',
                  color: 'darkNavy40',
                  textDecoration: 'none',
                  textAlign: 'center',
                  py: 3,
                }}
              >
                {nextGlossary?.term}
                <Box
                  as="svg"
                  viewBox="0 0 14 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  sx={{
                    width: 14,
                    height: 11,
                    ml: 4,
                  }}
                >
                  <path
                    d="M13.525 6.025L9.025 10.525C8.875 10.675 8.725 10.75 8.5 10.75C8.275 10.75 8.125 10.675 7.975 10.525C7.675 10.225 7.675 9.775 7.975 9.475L11.2 6.25H1C0.55 6.25 0.25 5.95 0.25 5.5C0.25 5.05 0.55 4.75 1 4.75H11.2L7.975 1.525C7.675 1.225 7.675 0.775 7.975 0.475C8.275 0.175 8.725 0.175 9.025 0.475L13.525 4.975C13.6 5.05 13.675 5.125 13.675 5.2C13.75 5.35 13.75 5.575 13.675 5.8C13.675 5.875 13.6 5.95 13.525 6.025Z"
                    fill="currentColor"
                  />
                </Box>
              </Link>
            )}
          </Box>
        </Container>
      </Section>
    </Layout>
  )
}

export default Term

export const pageQuery = graphql`
  query($termId: String, $nextTermId: String, $previousTermId: String) {
    glossary: contentfulGlossary(id: { eq: $termId }) {
      id
      term
      slug
      seoDescription {
        seoDescription
      }
      description {
        json
      }
    }
    nextGlossary: contentfulGlossary(id: { eq: $nextTermId }) {
      term
      slug
    }
    previousGlossary: contentfulGlossary(id: { eq: $previousTermId }) {
      term
      slug
    }
  }
`
